import {
  Box,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Link } from "gatsby";
import React from "react";
import { programsData } from "../../data/programsData";
import "./programs_popover.css";

interface Props {
  pathname: string;
  scrolling: boolean;
}
const ProgramsPopover = (props: Props) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { pathname, scrolling } = props;

  const activeProgram = pathname?.split("/")[2];
  const activeProgramIndex = programsData[1].programs.findIndex(
    (program) =>
      program.name?.toLowerCase().replace(/\s+/g, "-") === activeProgram
  );

  const activeSubProgram = pathname?.split("/")[3];
  const activeSubprogramIndex = programsData[1].programs[
    activeProgramIndex
  ]?.subPrograms.findIndex(
    (program) =>
      program.name?.toLowerCase().replace(/\s+/g, "-") === activeSubProgram
  );

  const [active, setActive] = React.useState<number>(-1);
  const [subActive, setSubActive] = React.useState<number>(-1);

  return (
    <Popover
      onClose={() => setActive(-1)}
      trigger="hover"
      preventOverflow
      onOpen={() => {
        setActive(activeProgramIndex !== -1 ? activeProgramIndex : 0);
        setSubActive(activeSubprogramIndex !== -1 ? activeSubprogramIndex : 0);
      }}
    >
      <PopoverTrigger>
        <Box
          cursor={"pointer"}
          className={
            pathname === "/" && !scrolling
              ? "hover-underline-animation landing-page-nav"
              : "hover-underline-animation"
          }
          fontSize="1rem"
          // m="0 1rem"
          rounded="md"
          color={pathname === "/" && !scrolling ? "#fff" : "#28329D"}
          _hover={{
            textDecoration: "none",
            color: pathname === "/" && !scrolling ? "#fff" : "#D65050",
          }}
        >
          Programs
        </Box>
      </PopoverTrigger>
      <PopoverContent w={"100%"}>
        {/* <PopoverArrow /> */}
        {/* <PopoverHeader>All programs</PopoverHeader> */}
        <PopoverBody
          bgColor={"#F2F2FB"}
          py={20}
          px={20}
          width="calc(100vw - 20px)"
          display={"flex"}
          gap={20}
        >
          <Box
            color={"primary.500"}
            fontSize={"md"}
            display={"flex"}
            flexDir={"column"}
            gap={4}
          >
            {programsData.map((program, index) => {
              if (program.programs.length == 0) {
                return (
                  <Link to={program.slug!}>
                    <Text
                      cursor={
                        pathname === program.slug + "/" ? "default" : "pointer"
                      }
                      textDecor={
                        pathname === program.slug + "/" ? "underline" : "none"
                      }
                      textDecorationColor={"primary.500"}
                      textUnderlineOffset={5}
                      className={
                        pathname === program.slug + "/"
                          ? ""
                          : "hover-underline-animation"
                      }
                    >
                      {program.name}
                    </Text>
                  </Link>
                );
              }
            })}
          </Box>
          <Box border={"0.2px solid"} borderColor={"primary.500"}></Box>
          <Box>
            {programsData.map((program) => {
              if (program.programs.length != 0) {
                return (
                  <Box
                    display={"flex"}
                    flexDir={"column"}
                    gap={4}
                    className="pop-over"
                  >
                    <Text color={"primary.500"}>{program.name}</Text>
                    <Box display={"flex"} flexDir={"column"} gap={2}>
                      {program.programs.map((subProgram, index) => (
                        <Popover
                          boundary={"scrollParent"}
                          trigger="hover"
                          placement={index <= 4 ? "right-start" : "right-end"}
                          preventOverflow
                          onClose={() => setSubActive(-1)}
                          isOpen={active === index}
                        >
                          <PopoverTrigger>
                            <Box display={"flex"} gap={2} alignItems={"center"}>
                              <Text
                                cursor={"pointer"}
                                onMouseEnter={() => setActive(index)}
                                fontSize={"sm"}
                                textDecor={
                                  active == index ? "underline" : "none"
                                }
                                textDecorationColor={"primary.600"}
                                textUnderlineOffset={5}
                                color={"primary.500"}
                              >
                                {subProgram.name}
                              </Text>
                              {/* {active == index && <Image h={4} src={Arrow} />} */}
                            </Box>
                          </PopoverTrigger>
                          <PopoverContent
                            border={"none"}
                            bgColor={"#F2F2FB"}
                            boxShadow={"none"}
                            height="100%"
                            width="100%"
                            display="flex"
                            justifyContent="center"
                          >
                            <PopoverBody
                              display={"flex"}
                              flexDir={"column"}
                              gap={4}
                            >
                              <Box
                                w={"20%"}
                                border={"1px solid"}
                                borderColor={"primary.600"}
                              ></Box>
                              <Box display={"flex"} flexDir={"column"} gap={2}>
                                {subProgram.subPrograms.map(
                                  (subPrograms, index) => {
                                    
                                    return (
                                      <Link
                                        to={subPrograms.slug}
                                        style={{ width: "fit-content" }}
                                      >
                                        <Text
                                          fontSize={"sm"}
                                          color={"primary.500"}
                                          onMouseEnter={() =>
                                            setSubActive(index)
                                          }
                                          onMouseLeave={() => setSubActive(-1)}
                                          cursor={
                                            activeSubProgram ===
                                            subPrograms.slug?.split('/')[3]
                                              ? "default"
                                              : "pointer"
                                          }
                                          textDecor={
                                            activeSubProgram ===
                                            subPrograms.slug?.split('/')[3]
                                              ? "underline"
                                              : "none"
                                          }
                                          textDecorationColor={"primary.500"}
                                          textUnderlineOffset={5}
                                          className={
                                            activeSubProgram ===
                                            subPrograms.slug?.split('/')[3]
                                              ? ""
                                              : "hover-underline-animation"
                                          }
                                        >
                                          {subPrograms.name}
                                        </Text>
                                      </Link>
                                    );
                                  }
                                )}
                              </Box>
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      ))}
                    </Box>
                  </Box>
                );
              }
            })}
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default ProgramsPopover;
