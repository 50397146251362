import {
  Box,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  Image,
  Stack,
  chakra,
  keyframes,
  shouldForwardProp,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Link } from "gatsby";
import Button from "../Button";
import { navigate } from "gatsby";

import Logo from "../../images/templogo.png";
import { ArrowBackIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { isValidMotionProp, motion } from "framer-motion";
import { programsData } from "../../data/programsData";

type MenuProps = {
  onClose: () => void;
  isOpen: boolean;
};

const ChakraBox = chakra(motion.div, {
  /**
   * Allow motion props and non-Chakra props to be forwarded.
   */
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const MenuDrawer = (props: MenuProps) => {
  const { onClose, isOpen } = props;
  const [xAxisValue, setXaxisValue] = useState(0);
  const [selectedProgram, setSelectedProgram] = useState({
    name: "",
    subPrograms: [{ name: "", slug: "" }],
  });
  const increaseXValue = () => {
    setXaxisValue(xAxisValue + 100);
  };
  const decreaseXValue = () => {
    setXaxisValue(xAxisValue - 100);
  };
  const show = {
    opacity: 1,
    display: "block",
  };

  const hide = {
    opacity: 0,
    // transitionEnd: {
    //   display: "none",
    // },
  };
  const Links = [
    { name: "Programs", link: "programs/express-entry" },
    { name: "About", link: "about" },
    { name: "Contact", link: "contact" },
  ];
  return (
    <Drawer
      onClose={onClose}
      isOpen={isOpen}
      size={{ base: "full" }}
      placement="left">
      <DrawerOverlay />
      <DrawerContent bgColor="primary.500">
        <DrawerCloseButton color="white" fontSize="md" mr={3} />
        <DrawerBody
          px={0}
          display="flex"
          flexDir="column"
          alignItems="start"
          gap={4}
          justifyContent="start"
          overflow="hidden">
          <ChakraBox
            initial={{ x: "0%" }}
            animate={{ x: xAxisValue + "%" }}
            // @ts-ignore no problem in operation, although type error appears.
            transition={{ duration: "0.3", type: "spring" }}
            display="flex"
            flexDir="row"
            alignItems="center"
            justifyContent={"flex-start"}
            w="100%"
            mt={8}>
            <ChakraBox
              display="flex"
              flexDir="column"
              minW="100%"
              alignItems="start"
              px={6}
              gap={3}>
              <Box
                fontSize="3xl"
                fontWeight="semibold"
                color="white"
                cursor="pointer"
                onClick={decreaseXValue}>
                Programs
                <ChevronRightIcon boxSize={6} color={"primary.600"} />
              </Box>
              <Divider borderColor="rgba(255, 255, 255, 0.20)" w="50%" />
              {Links.filter((data) => data.name !== "Programs").map(
                (link, index) => (
                  <>
                    <Link
                      to={`/${link.link.toLocaleLowerCase()}`}
                      onClick={onClose}>
                      <Box fontSize="3xl" fontWeight="semibold" color="white">
                        {link.name}{" "}
                        {link.name === "Programs" && (
                          <ChevronRightIcon boxSize={6} color={"primary.600"} />
                        )}
                      </Box>
                    </Link>
                    {index !== Links.length - 2 && (
                      <Divider
                        borderColor="rgba(255, 255, 255, 0.20)"
                        w="50%"
                      />
                    )}
                  </>
                )
              )}

              <Button
                label="Calculate your CRS Score!"
                onClick={() => {
                  navigate("/calculator");
                  onClose();
                }}
                bgColor="rgba(255, 255, 255, 0.20)"
              />
            </ChakraBox>

            <ChakraBox
              display="flex"
              flexDir="column"
              gap={5}
              minW="100%"
              px={6}>
              <Box fontSize="3xl" fontWeight="semibold" color="white">
                Programs
              </Box>
              <Box display="flex" flexDir="column" gap={3}>
                {programsData.map((data, index) => (
                  <>
                    {data.programs.length > 0 ? (
                      <Box display="flex" flexDir="column">
                        <Box
                          fontSize="md"
                          color="white"
                          cursor="pointer"
                          onClick={decreaseXValue}>
                          {data.name}
                          <ChevronRightIcon boxSize={6} color={"primary.600"} />
                        </Box>
                      </Box>
                    ) : (
                      <Link
                        to={`${data.slug?.toLocaleLowerCase()}`}
                        onClick={onClose}>
                        <Box fontSize="md" color="white">
                          {data.name}{" "}
                        </Box>
                      </Link>
                    )}
                    {index !== programsData.length - 1 && (
                      <Divider borderColor="rgba(255, 255, 255, 0.20)" />
                    )}
                  </>
                ))}
              </Box>
            </ChakraBox>

            <ChakraBox
              display="flex"
              flexDir="column"
              gap={5}
              minW="100%"
              px={6}>
              <Box fontSize="3xl" fontWeight="semibold" color="white">
                Provincial Nominee Programs
              </Box>
              <Box>
                {programsData.map((data) => (
                  <>
                    {data.programs.length > 0 && (
                      <Box display="flex" flexDir="column" gap={3}>
                        {data.programs.map((program, index) => (
                          <>
                            <Box
                              fontSize="md"
                              color="white"
                              cursor="pointer"
                              onClick={() => {
                                setSelectedProgram(program);
                                decreaseXValue();
                              }}>
                              {program.name}
                              <ChevronRightIcon
                                boxSize={6}
                                color={"primary.600"}
                              />
                            </Box>
                            {index !== data.programs.length - 1 && (
                              <Divider borderColor="rgba(255, 255, 255, 0.20)" />
                            )}
                          </>
                        ))}
                      </Box>
                    )}
                  </>
                ))}
              </Box>
            </ChakraBox>

            <ChakraBox
              display="flex"
              flexDir="column"
              gap={5}
              minW="100%"
              px={6}>
              <Box fontSize="3xl" fontWeight="semibold" color="white">
                {selectedProgram?.name}
              </Box>
              <Box display="flex" flexDir="column" gap={3}>
                {selectedProgram?.subPrograms.map((data, index) => (
                  <>
                    {
                      <Link
                        to={`${data.slug?.toLocaleLowerCase()}`}
                        onClick={onClose}>
                        <Box fontSize="md" color="white">
                          {data.name}{" "}
                        </Box>
                      </Link>
                    }
                    {index !== selectedProgram?.subPrograms.length - 1 && (
                      <Divider borderColor="rgba(255, 255, 255, 0.20)" />
                    )}
                  </>
                ))}
              </Box>
            </ChakraBox>
          </ChakraBox>
          <ChakraBox
            // position={"absolute"}
            // bottom={36}
            // right={20}
            animate={xAxisValue < 0 ? show : hide}>
            <IconButton
              mx={6}
              _active={{ bgColor: "rgba(255, 255, 255, 0.20)" }}
              _hover={{ bgColor: "rgba(255, 255, 255, 0.20)" }}
              size={"lg"}
              isRound={true}
              variant="solid"
              isDisabled={xAxisValue === 0}
              bgColor="rgba(255, 255, 255, 0.20)"
              aria-label="Done"
              fontSize={"4xl"}
              icon={<ArrowBackIcon color="white" />}
              onClick={() => {
                increaseXValue();
              }}
            />
          </ChakraBox>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default MenuDrawer;
