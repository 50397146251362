import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import Layout from "./components/Layout";
import theme from "./@chakra-ui/gatsby-plugin/theme";

export const wrapPageElement = ({ element }: { element: React.ReactNode }) => {
  return (
    <ChakraProvider theme={theme} resetCSS>
      <Layout>{element}</Layout>
      <div
        data-chat-widget
        data-location-id="9hPz5k9DKlFje0jUnEaR"
        data-style="--chat-widget-primary-color: #242582; --chat-widget-active-color:#242582 ;--chat-widget-bubble-color: #50519b ;"
        data-support-contact="info@immigrationaims.com"
        data-agency-name="Immigration Aims Inc."
        data-agency-website="www.immigrationaims.com"
        data-locale="en-us"
        data-primary-color="#242582"></div>
      <script
        src="https://widgets.leadconnectorhq.com/loader.js"
        data-resources-url="https://widgets.leadconnectorhq.com/chat-widget/loader.js"></script>
    </ChakraProvider>
  );
};
