import * as React from "react";
import {
  Box,
  Flex,
  HStack,
  useDisclosure,
  Image,
  Text,
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
  PopoverCloseButton,
  PopoverArrow,
  PopoverHeader,
  PopoverBody,
} from "@chakra-ui/react";
import { Link } from "gatsby";
import { HamburgerIcon } from "@chakra-ui/icons";
import MenuDrawer from "../Drawer";
import Logo from "../../images/templogo.png";
import { useLocation } from "@reach/router";
import "./Header.css";
import FooterLogo from "../../images/ImmigrationAims_Logo_Dark_transparent.png";
import { programsData } from "../../data/programsData";
import ProgramsPopover from "../ProgramsPopover";

interface Props {
  children: React.ReactNode;
  path: string;
  pathname: string;
  scrolling: boolean;
}

const Links = ["About", "Contact"];

const NavLink = (props: Props) => {
  const { children, path, pathname, scrolling } = props;
  return (
    <Link to={`/${path.toLocaleLowerCase()}`}>
      <Box
        className={
          pathname === "/" && !scrolling
            ? "hover-underline-animation landing-page-nav"
            : "hover-underline-animation"
        }
        fontSize="1rem"
        m="0 1rem"
        rounded="md"
        color={pathname === "/" && !scrolling ? "#fff" : "#28329D"}
        _hover={{
          textDecoration: "none",
          color: pathname === "/" && !scrolling ? "#fff" : "#D65050",
        }}
      >
        {children}
      </Box>
    </Link>
  );
};

const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { pathname } = useLocation();

  const handleClick = () => {
    onOpen();
  };

  const [scrolling, setScrolling] = React.useState(false);

  React.useEffect(() => {
    // Add a scroll event listener to the window
    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    // Check the scroll position and update the state
    if (window.scrollY > 100) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  return (
    <>
      <Box
        transition={"background-color 0.5s ease"}
        position={pathname === "/contact/" ? "fixed" : "sticky"}
        background={pathname === "/" && !scrolling ? "transparent" : "#fff"}
        top={0}
        width="100%"
        zIndex={111}
        // maxW={"8xl"}
      >
        <Flex h="50px" alignItems={"center"} justifyContent={"space-between"}>
          <MenuDrawer isOpen={isOpen} onClose={onClose} />
          <HamburgerIcon
            aria-label={"Open Menu"}
            ml="1rem"
            fontSize="2xl"
            display={{ md: "none" }}
            transition={"background-color 0.5s ease"}
            color={pathname === "/" && !scrolling ? "#fff" : "#000"}
            onClick={handleClick}
          />
          <HStack
            spacing={8}
            alignItems="center"
            width="100%"
            display="flex"
            height="100%"
            ml={50}
            justifyContent="space-between"
          >
            <Box
              marginLeft={{ base: 10, sm: 10, md: 50 }}
              transition={"background-color 0.3s ease"}
            >
              <Link to="/">
                {pathname === "/" && !scrolling ? (
                  <Image src={FooterLogo} alt="logo" width={150} />
                ) : (
                  <Image src={Logo} alt="logo" width={150} />
                )}
              </Link>
            </Box>

            <HStack
              as={"nav"}
              spacing={4}
              fontWeight={500}
              height="100%"
              display={{ base: "none", md: "flex" }}
            >
              <ProgramsPopover pathname={pathname} scrolling={scrolling} />
              {Links.map((link) => (
                <NavLink
                  key={link}
                  path={link}
                  pathname={pathname}
                  scrolling={scrolling}
                >
                  {link}
                </NavLink>
              ))}

              <Box
                color="#fff"
                backgroundColor="#242582"
                height="100%"
                display="flex"
                alignItems="center"
                padding="0  1.8rem"
              >
                <Link to="/calculator">
                  <Text
                    className="arrow-button"
                    display="flex"
                    alignItems="center"
                    padding="0  2.5rem"
                  >
                    Your CRS Score<span className="arrow"></span>
                  </Text>
                </Link>
              </Box>
            </HStack>
          </HStack>
        </Flex>

        {/* {isOpen ? (
          <Box
            pb={{ base: 0, sm: 0, md: 4, lg: 4, xl: 4, "2xl": 4 }}
            display={{ md: "none" }}
            position="absolute"
            transition="all .3s ease"
            background="#fff"
            boxShadow="6px 6px 10px 0px rgba(0, 0, 0, 0.25)"
          >
            <Stack as="nav" spacing={4}>
              {Links.map((link) => (
                <NavLink key={link} path={link}>
                  {link}
                </NavLink>
              ))}

              <Box
                color="#fff"
                backgroundColor="#242582"
                height="100%"
                display="flex"
                alignItems="center"
                padding={{
                  base: "0.5rem 1.8rem",
                  sm: "0.5rem 1.8rem",
                  md: "0 1.8rem",
                  lg: "0 1.8rem",
                  xl: "0 1.8rem",
                  "2xl": "0 1.8rem",
                }}
              >
                <Text
                  className="arrow-button"
                  display="flex"
                  alignItems="center"
                >
                  Your CRS Score<span className="arrow"></span>
                </Text>
              </Box>
            </Stack>
          </Box>
        ) : null} */}
      </Box>
    </>
  );
};
export default Header;
