exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-calculator-index-tsx": () => import("./../../../src/pages/calculator/index.tsx" /* webpackChunkName: "component---src-pages-calculator-index-tsx" */),
  "component---src-pages-cardtest-index-tsx": () => import("./../../../src/pages/cardtest/index.tsx" /* webpackChunkName: "component---src-pages-cardtest-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacy-policy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-pages-programs-index-tsx": () => import("./../../../src/pages/programs/index.tsx" /* webpackChunkName: "component---src-pages-programs-index-tsx" */),
  "component---src-pages-programs-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/programs/{MarkdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-programs-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-terms-of-service-index-tsx": () => import("./../../../src/pages/terms-of-service/index.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-index-tsx" */)
}

