import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import { Divider } from "@chakra-ui/react";
import { emailValidation } from "../../validations";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import FooterLogo from "../../images/ImmigrationAims_Logo_Dark_transparent.png";

const Footer = (props: any) => {
  let { pathname } = useLocation();

  const [email, setEmail] = React.useState("");
  //Handle Email Validation
  const [isEmailValid, setEmailValid] = React.useState(true);
  const handleEmail = (e: any) => {
    let email = e.target.value.trim();

    if (!email || emailValidation().test(email) === false) {
      setEmailValid(false);
    } else {
      setEmailValid(true);
      setEmail(email);
    }
  };
  const handleSubmit = () => {
    console.log("email", email);
  };

  return (
    <Box w="100%" backgroundColor="primary.500">
      <Flex
        color="#E5E5E5"
        paddingY={{ base: 5, sm: 5, md: 10, lg: 20 }}
        paddingX={{ base: 4, sm: 10, md: 18, lg: 32 }}
        justify="space-between"
        flexDirection={{ base: "column", sm: "column", md: "row" }}
        maxW="8xl"
        margin="0 auto">
        <Flex direction="column" gap={2.5} padding={2.5} flex={1}>
          <Image src={FooterLogo} alt="logo" width={148} mt={2} />
          <Text fontSize={{ base: "sm", sm: "sm", md: "md" }}>
            © 2023 Immigration Aims.
            <br />
            All rights reserved
          </Text>

          <Text fontSize={{ base: "sm", sm: "sm", md: "md" }}>
            2000 Argentia Rd, Plaza 4, Unit #490 <br /> Mississauga, Ontario,
            L5N1W1 <br />
            Canada
          </Text>
          {/* <Text fontSize={{ base: "sm", sm: "sm", md: "md" }}>0000 00000000</Text> */}
        </Flex>
        <Divider
          display={{ base: "block", sm: "block", md: "none" }}
          marginY={5}
        />
        <Flex justify="space-between" flex={2}>
          <Flex direction="column" gap={2.5} padding={2.5} flex={1}>
            <Text
              fontSize={{ base: "md", sm: "md", lg: "2xl" }}
              fontWeight={600}>
              Links
            </Text>
            <Link to="/">
              <Text
                fontWeight={pathname === "/" ? 700 : 500}
                fontSize={{ base: "sm", sm: "sm", md: "md" }}
                color={pathname === "/" ? "primary.600" : ""}
                className={pathname === "/" ? "" : "hover-underline-animation"}>
                Home
              </Text>
            </Link>
            <Link to="/programs/express-entry/">
              <Text
                fontWeight={pathname === "/programs/express-entry/" ? 700 : 500}
                color={
                  pathname === "/programs/express-entry/" ? "primary.600" : ""
                }
                fontSize={{ base: "sm", sm: "sm", md: "md" }}
                className={
                  pathname === "/programs/express-entry/"
                    ? ""
                    : "hover-underline-animation"
                }>
                Programs
              </Text>
            </Link>
            <Link to="/about">
              <Text
                fontWeight={pathname === "/about/" ? 700 : 500}
                color={pathname === "/about/" ? "primary.600" : ""}
                fontSize={{ base: "sm", sm: "sm", md: "md" }}
                className={
                  pathname === "/about/" ? "" : "hover-underline-animation"
                }>
                About
              </Text>
            </Link>
            <Link to="/contact">
              <Text
                fontWeight={pathname === "/contact/" ? 700 : 500}
                color={pathname === "/contact/" ? "primary.600" : ""}
                fontSize={{ base: "sm", sm: "sm", md: "md" }}
                className={
                  pathname === "/contact/" ? "" : "hover-underline-animation"
                }>
                Contact
              </Text>
            </Link>
            <Link to="/calculator">
              <Text
                fontWeight={pathname === "/calculator/" ? 700 : 500}
                color={pathname === "/calculator/" ? "primary.600" : ""}
                fontSize={{ base: "sm", sm: "sm", md: "md" }}
                className={
                  pathname === "/calculator/" ? "" : "hover-underline-animation"
                }>
                CRS Calculator
              </Text>
            </Link>
            {/* <Link to="/call">
            <Text
              fontWeight={pathname === "/call/" ? 700 : 500}
              color={pathname === "/call/" ? "primary.600" : ""}
              fontSize={{ base: "sm", sm: "sm", md: "md" }}
              className={
                pathname === "/call/" ? "" : "hover-underline-animation"
              }>
              Book a call
            </Text>
          </Link> */}
          </Flex>

          <Flex direction="column" gap={2.5} padding={2.5} flex={1}>
            <Text
              fontSize={{ base: "md", sm: "md", lg: "2xl" }}
              fontWeight={600}>
              Support
            </Text>
            <Link to="/terms-of-service">
              <Text
                fontWeight={500}
                fontSize={{ base: "sm", sm: "sm", md: "md" }}>
                Terms of Service
              </Text>
            </Link>
            <Link to="/privacy-policy">
              <Text
                fontWeight={500}
                fontSize={{ base: "sm", sm: "sm", md: "md" }}>
                Privacy policy
              </Text>
            </Link>
          </Flex>
        </Flex>
        {/* <Divider
        display={{ base: "block", sm: "block", md: "none" }}
        marginY={5}
      /> */}
        {/* <Flex direction="column" gap={5} padding={2.5} flex={1}>
        <Text fontSize={{ base: "md", sm: "md", lg: "2xl" }} fontWeight={600}>
          Stay up to date
        </Text>
        <FormControl
          width={{ base: "100%", sm: "fit-content", md: "fit-content" }}
        >
          <InputGroup>
            <Input
              type="email"
              placeholder="Email Address"
              background="#EFEFEF"
              color="#1a202c"
              _placeholder={{ color: "#24258280" }}
              onChange={handleEmail}
            />
            <InputRightElement cursor="pointer" onClick={handleSubmit}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z"
                  fill="#F64C72"
                />
              </svg>
            </InputRightElement>
          </InputGroup>
          {!isEmailValid && (
            <FormHelperText fontSize="12px" color="#FF3333">
              Enter a valid email address
            </FormHelperText>
          )}
        </FormControl>
      </Flex> */}
      </Flex>
    </Box>
  );
};

export default Footer;
