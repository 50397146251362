import React from "react";
import { Button } from "@chakra-ui/react";
import { ButtonProps } from "../../interfaces";

const Btn = (props: ButtonProps) => {
  const { label, onClick, isDisabled, icon, id, bgColor } = props;
  return (
    <Button
      id={id?.toString()}
      onClick={onClick}
      isDisabled={isDisabled}
      color="white"
      bgColor={bgColor || "primary.500"}
      size="md"
      rightIcon={icon}
      paddingX={{ base: 4, md: 9 }}
      _hover={{
        bgColor: "primary.600",
        boxShadow: "4px 4px 0px 0px var(--chakra-colors-primary-500)",
        "& svg": {
          color: "white",
        },
      }}>
      {label}
    </Button>
  );
};

export default Btn;
